var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{tag:"component"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Kullanıcı","label-for":"sKodu"}},[_c('personel-selection',{attrs:{"personelSelect":_vm.kullaniciId},on:{"update:personelSelect":function($event){_vm.kullaniciId=$event},"update:personel-select":function($event){_vm.kullaniciId=$event}}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Kod","label-for":"sKodu"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Kod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sKodu","state":errors.length > 0 ? false : null},model:{value:(_vm.satisPersonel.sKodu),callback:function ($$v) {_vm.$set(_vm.satisPersonel, "sKodu", $$v)},expression:"satisPersonel.sKodu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Adı","label-for":"sAdi"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Adı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sAdi","state":errors.length > 0 ? false : null},model:{value:(_vm.satisPersonel.sAdi),callback:function ($$v) {_vm.$set(_vm.satisPersonel, "sAdi", $$v)},expression:"satisPersonel.sAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Soyadı","label-for":"sSoyadi"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Soyadı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sSoyadi","state":errors.length > 0 ? false : null},model:{value:(_vm.satisPersonel.sSoyadi),callback:function ($$v) {_vm.$set(_vm.satisPersonel, "sSoyadi", $$v)},expression:"satisPersonel.sSoyadi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Telefon","label-for":"sTelefon"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Telefon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"sTelefon","state":errors.length > 0 ? false : null,"placeholder":"(999) 999-9999","raw":false,"options":_vm.options.phone},model:{value:(_vm.satisPersonel.sTelefon),callback:function ($$v) {_vm.$set(_vm.satisPersonel, "sTelefon", $$v)},expression:"satisPersonel.sTelefon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"E-Posta","label-for":"sEPosta"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"E-Posta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sEPosta","state":errors.length > 0 ? false : null},model:{value:(_vm.satisPersonel.sEPosta),callback:function ($$v) {_vm.$set(_vm.satisPersonel, "sEPosta", $$v)},expression:"satisPersonel.sEPosta"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('save-button',{attrs:{"onClickSave":_vm.saveSatisPersonel.bind(true),"showSaveAndClose":false}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }