<template>
  <component :is="'b-card'">
    <!-- Header: Personal Info -->

    <!-- Form: Personal Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Kullanıcı" label-for="sKodu">
              <personel-selection :personelSelect.sync="kullaniciId" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Kod" label-for="sKodu">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Kod"
              >
                <b-form-input
                  id="sKodu"
                  v-model="satisPersonel.sKodu"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Ad -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Adı" label-for="sAdi">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Adı"
              >
                <b-form-input
                  id="sAdi"
                  v-model="satisPersonel.sAdi"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Soyad -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Soyadı" label-for="sSoyadi">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Soyadı"
              >
                <b-form-input
                  id="sSoyadi"
                  v-model="satisPersonel.sSoyadi"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Telefon -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Telefon" label-for="sTelefon">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Telefon"
              >
                <cleave
                  id="sTelefon"
                  v-model="satisPersonel.sTelefon"
                  :state="errors.length > 0 ? false : null"
                  placeholder="(999) 999-9999"
                  class="form-control"
                  :raw="false"
                  :options="options.phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: EPosta -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="E-Posta" label-for="sEPosta">
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="E-Posta"
              >
                <b-input-group>
                  <b-form-input
                    id="sEPosta"
                    v-model="satisPersonel.sEPosta"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <save-button
              :onClickSave="saveSatisPersonel.bind(true)"
              :showSaveAndClose="false"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
// Alert
import AlertService from '@/common/alert.service'
import Cleave from 'vue-cleave-component'
// Options
import StaticOptions from '@/common/options/StaticOptions';
import PersonelSelection from '@/views/management/selection/PersonelSelection.vue';
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import SaveButton from '@/components/SaveButton.vue';

export default {
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
    SaveButton,
    PersonelSelection,
  },

  data() {
    return {
      required,
      min,
      max,
      satisPersonel: {
        sKodu: null,
        sAdi: null,
        sSoyadi: null,
        sEPosta: null,
        sTelefon: null,
        kullanici_Id: 0,
      },
      urunGrupTurOptions: StaticOptions.urunGrupTurOptions,
      urunGrupModulOptions: StaticOptions.urunGrupModulOptions,
      // Cleave options
      options: {
        phone: {
          blocks: [0, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['(', ')', ' ', '-', '-'],
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },
    }
  },

  methods: {
    fetchSatisPersonel() {
      const id = parseInt(this.$router.currentRoute.params.id, 10);
      if (id !== -1) {
        this.$store.dispatch('satisPersonelYonetim/fetchSatisPersonel', { id })
          .then(response => {
            if (response.statusCode === 200) {
              this.satisPersonel = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      } else {
        this.isCreate = true
      }
    },

    saveSatisPersonel() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('satisPersonelYonetim/saveSatisPersonel', this.satisPersonel)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },
    fillForm(id) {
      this.$store.dispatch('fetchKullanici', { id })
        .then(response => {
          if (response.statusCode === 200) {
            this.satisPersonel.sAdi = response.result.sAd;
            this.satisPersonel.sSoyadi = response.result.sSoyad;
            this.satisPersonel.sEPosta = response.result.sEMail;
            this.satisPersonel.sTelefon = response.result.sTelefon;
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  computed: {
    kullaniciId: {
      get() {
        return this.satisPersonel.kullanici_Id;
      },
      set(val) {
        this.satisPersonel.kullanici_Id = val;
        if (val !== null) this.fillForm(val)
      },
    },
  },
  created() {
    this.fetchSatisPersonel();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
